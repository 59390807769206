import CustomText from "@/components/elements/CustomText";
import { CERTIFIED_ITEM } from "@/utils/RandomPicker";
import { mediaQuery, useMediaQuery } from "@/utils/UseMediaQuery";
import { Box, Card, Image } from "@chakra-ui/react";
import gsap from "gsap";
import { useEffect, useRef } from "react";

export function ReelNumbers({
  items,
  currentNumber,
  fps,
}: {
  items: Array<CERTIFIED_ITEM>;
  currentNumber: number;
  fps: number;
}) {
  const isPC = useMediaQuery(mediaQuery.pc);
  const isTablet = useMediaQuery(mediaQuery.tablet);

  const lastNumberRef = useRef<number>(-1);
  const isInitRef = useRef<boolean>(true);
  const scrollRef = useRef<HTMLOListElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      if (
        0 < lastNumberRef.current &&
        lastNumberRef.current !== currentNumber
      ) {
        isInitRef.current = false;
      }

      const progress = {
        current: 0,
      };

      gsap.to(progress, {
        current: 1,
        duration: isInitRef.current ? 0 : 1 / fps,
        ease: "linear",
        onUpdate: () => {
          if (scrollRef.current) {
            scrollRef.current.style.transform = `translateY(calc(${getNewY(
              currentNumber,
              progress.current,
            )}%)) translateY(22px)`;
          }
        },
        onComplete: () => {
          isInitRef.current = false;
        },
      });
    }

    lastNumberRef.current = currentNumber;

    function getNewY(newNumber: number, progress: number) {
      newNumber = (newNumber % 10) + 1;
      return (
        (-100 / 15) * (newNumber - 1) -
        (100 / 15) * progress -
        (isPC ? 0 : isTablet ? 2.2 : 5)
      );
    }
  }, [currentNumber]);

  return (
    <Box w="full" position="relative">
      <Card
        w="full"
        minW={`${isPC ? 500 : isTablet ? 350 : 250}px`}
        maxW={`${isPC ? 500 : isTablet ? 350 : 250}px`}
        h={`${isPC ? 80 : isTablet ? 55 : 35}px`}
        position="absolute"
        left="50%"
        transform="translate(-50%, 0)"
        overflow="hidden"
        bg="background.secondary"
        border="border: 1px solid #ccc;"
      >
        <ol ref={scrollRef}>
          {[...new Array(15)].map((_, i) => {
            return (
              <li key={i}>
                <CustomText
                  px="5%"
                  size="xl"
                  font="serif"
                  textAlign="center"
                  mx="auto"
                  isTruncated
                >
                  {items.length > 0 ? items[i % 10].name : ""}
                </CustomText>
              </li>
            );
          })}
        </ol>
      </Card>
      <Box
        position="absolute"
        left="50%"
        top={`${isPC ? -10 : isTablet ? -8 : -5}px`}
        transform="translate(-50%, 0)"
        w="full"
        h={`${isPC ? 100 : isTablet ? 70 : 45}px`}
        minW={`${isPC ? 580 : isTablet ? 400 : 300}px`}
        maxW={`${isPC ? 580 : isTablet ? 400 : 300}px`}
        zIndex="10"
      >
        <Image
          loading="lazy"
          alt="枠"
          w="full"
          h="full"
          src={`images/${isPC ? "PC" : isTablet ? "Tablet" : "SP"}/waku.webp`}
        />
      </Box>
    </Box>
  );
}

export default ReelNumbers;
