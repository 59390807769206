import { AspectRatio, Box } from "@chakra-ui/react";
import "@splidejs/react-splide/css";
import YouTube from "react-youtube";
// import { Autoplay, Navigation, Pagination } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "@css/custom_swiper.css";

export const Top = () => {
  // const [playerState, setPlayerState] = useState(-1);

  return (
    <>
      <Box>
        <AspectRatio ratio={16 / 9}>
          <YouTube
            videoId="NLnl17ZSYyk"
            opts={{
              width: "100%",
              height: "100%",
              aspectRacio: "16/9",
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                loop: 1,
                autoplay: 1,
                mute: 1,
                rel: 0,
                playlist: "NLnl17ZSYyk",
              },
            }}
            // onStateChange={(event) => {
            //   setPlayerState(event.data);
            // }}
          />
        </AspectRatio>

        {/* <Swiper
          modules={[Autoplay, Navigation, Pagination]}
          loop
          navigation
          speed={1500}
          autoplay={{
            delay: 5000,
          }}
          pagination
          style={{
            borderRadius: "30px",
            overflow: "hidden",
          }}
        >
          {topImgSources.map((imgPath, idx) => {
            return (
              <SwiperSlide key={`topswiper_${idx}`}>
                {idx == 0 ? (
                  <Box position="relative">
                    <Image 
 loading="lazy"
                      w="full"
                      src={imgPath}
                      alt={IMG_DISCRIPTIONS[idx]}
                      loading="lazy"
                    />
                    <Box
                      className="fade_right"
                      position="absolute"
                      w="full"
                      top="62%"
                    >
                      <CustomText
                        textAlign="center"
                        color="primary"
                        font="serif"
                        fontWeight="bold"
                        fontSize="6vw"
                        lineHeight="90%"
                        fontStyle="italic"
                      >
                        水に流せるまち
                      </CustomText>
                      <CustomText
                        textAlign="center"
                        color="primary"
                        font="serif"
                        fontWeight="bold"
                        fontSize="6vw"
                        fontStyle="italic"
                      >
                        南島原市
                      </CustomText>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Image 
 loading="lazy"
                      w="full"
                      src={imgPath}
                      alt={IMG_DISCRIPTIONS[idx]}
                      loading="lazy"
                    />
                  </Box>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper> */}
      </Box>
    </>
  );
};
