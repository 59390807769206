import { mediaQuery, useMediaQuery } from "@/utils/UseMediaQuery";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

import {
  CERTIFIED_ITEM,
  CERTIFIED_ITEM_DATA,
  RandomDataPicker,
} from "@/utils/RandomPicker";
import { Box, Card, Image, Link } from "@chakra-ui/react";
import "@splidejs/react-splide/css";
import { useEffect, useState } from "react";

type ItemViewerProps = {
  isComingSoon: boolean;
};

export const ItemViewer: React.FC<ItemViewerProps> = ({ isComingSoon }) => {
  const isPC = useMediaQuery(mediaQuery.pc);
  const isTablet = useMediaQuery(mediaQuery.tablet);
  const options = {
    fixedWidth: `${isPC ? 20 : isTablet ? 35 : 45}%`,
    type: "loop",
    speed: 0.5,
    flickPower: 300,
    arrows: false,
    gap: "1.5%",
    pagination: false,
    autoScroll: {
      pauseOnHover: isPC ? true : false,
      pauseOnFocus: false,
      speed: `${isPC ? 1 : 0.7}` as unknown as number,
    },
  };
  const [itemData, setItemData] = useState(Array<CERTIFIED_ITEM>);

  useEffect(() => {
    setItemData(RandomDataPicker(CERTIFIED_ITEM_DATA, 15));
  }, [isPC, isTablet]);

  return isComingSoon ? (
    <></>
  ) : (
    <Box py="10%" pt="3%" position="relative" overflow="hidden">
      {/* <Box mx="auto" mb="4%">
        <CustomText textAlign="center" fontWeight="600" size="xl">
          おいしい南島原ブランド
        </CustomText>
      </Box> */}
      <Box
        w={`${isPC ? "22%" : isTablet ? "30%" : "30%"}`}
        position="absolute"
        zIndex="100"
        top={`${isPC ? "-30%" : isTablet ? "-35%" : "-20%"}`}
        left="-5%"
        overflow="hidden"
      >
        <Box
          w="100%"
          h="100%"
          bg="transparent"
          position="absolute"
          left="-15%"
          zIndex="-1"
        />
        <Image
          alt="指をさす美川さん左"
          src={`images/${isPC ? "PC" : "Tablet"}/mikawa/mikawa_left.webp`}
        />
      </Box>
      <Box
        w={`${isPC ? "22%" : isTablet ? "30%" : "30%"}`}
        position="absolute"
        zIndex="100"
        top={`${isPC ? "-30%" : isTablet ? "-35%" : "-20%"}`}
        right="-5%"
        overflow="hidden"
      >
        <Box
          w="100%"
          h="100%"
          bg="transparent"
          position="absolute"
          right="-15%"
          zIndex="-1"
        />
        <Image
          alt="指をさす美川さん右"
          src={`images/${isPC ? "PC" : "Tablet"}/mikawa/mikawa_right.webp`}
        />
      </Box>
      <Splide options={options} extensions={{ AutoScroll }}>
        <SplideSlide>
          <Link target="_blank" href="https://oishii-minamishimabara.jp/">
            <Card overflow="hidden" _hover={{ cursor: "pointer" }}>
              <Image
                loading="lazy"
                w="full"
                src="https://www.city.minamishimabara.lg.jp/kiji00310610/3_10610_56979_up_bpt4ybim.png"
                alt="おいしい南島原ブランドホームページ"
              />
            </Card>
          </Link>
        </SplideSlide>
        {itemData.map((data, idx) => {
          return (
            <SplideSlide key={idx}>
              <Link target="_blank" href={data.url}>
                <Card overflow="hidden" _hover={{ cursor: "pointer" }}>
                  <Image
                    src={`images/${isPC ? "PC" : "Tablet"}/items/${
                      data.img_url
                    }`}
                    alt={data.name}
                  />
                </Card>
              </Link>
            </SplideSlide>
          );
        })}
      </Splide>
    </Box>
  );
};
