import CustomText from "@/components/elements/CustomText";
import { mediaQuery, useMediaQuery } from "@/utils/UseMediaQuery";
import { Box, Image, Stack } from "@chakra-ui/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";

gsap.registerPlugin(ScrollTrigger);

export const Message = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    setAnimation();
  }, []);

  const fadeInTargets = [
    "#messageTitle",
    "#message0",
    "#message1",
    "#message2",
    "#message3",
  ];

  const titleRef = useRef<HTMLDivElement>(null);
  const message0Ref = useRef<HTMLDivElement>(null);
  const message1Ref = useRef<HTMLDivElement>(null);
  const message2Ref = useRef<HTMLDivElement>(null);
  const message3Ref = useRef<HTMLDivElement>(null);
  const arrRef = [titleRef, message0Ref, message1Ref, message2Ref, message3Ref];

  const setAnimation = () => {
    fadeInTargets.forEach((target, idx) => {
      // const basePoint = 65;
      gsap.to(target, {
        scrollTrigger: {
          trigger: target,
          start: idx == arrRef.length - 1 ? "top 75%" : "top 65%",
          end: "bottom bottom",
          markers: false,
          onEnter: () => {
            arrRef[idx].current?.classList.add("active");
          },
        },
      });
    });
  };

  const isPC = useMediaQuery(mediaQuery.pc);
  const isTablet = useMediaQuery(mediaQuery.tablet);
  return (
    <>
      <Box ref={titleRef} className="fade_in">
        <Box>
          <Image
            alt="水に流せるまち"
            loading="lazy"
            id="messageTitle"
            mx="auto"
            w="50%"
            src="images/machi_logo.png"
          />
        </Box>
      </Box>
      <Stack my="2%" spacing={isPC ? "25px" : isTablet ? "10px" : "5px"}>
        <Box ref={message0Ref} className="fade_in">
          <CustomText
            id="message0"
            w="full"
            font="serif"
            fontStyle="italic"
            textAlign="center"
            fontWeight="bold"
            color="primary"
            size="lg"
            lineHeight="2.0"
          >
            些細なこと。過去のこと。
            <br />
            他人のこと。自分のこと。
            <br />
          </CustomText>
        </Box>
        <Box ref={message1Ref} className="fade_in">
          <CustomText
            id="message1"
            w="full"
            font="serif"
            fontStyle="italic"
            textAlign="center"
            fontWeight="bold"
            color="primary"
            size="lg"
            lineHeight="2.0"
          >
            なんだか許せないことがあるのなら、
            <br />
            南島原に来てみませんか。
            <br />
          </CustomText>
        </Box>
        <Box ref={message2Ref} className="fade_in">
          <CustomText
            id="message2"
            w="full"
            font="serif"
            fontStyle="italic"
            textAlign="center"
            fontWeight="bold"
            color="primary"
            size="lg"
            lineHeight="2.0"
          >
            自然に癒され、歴史を感じ、
            <br />
            極上の島原手延そうめんを食べれば、
            <br />
            あなたのわだかまりも、
            <br />
            そうめんのようにさらさらと水に流せるはず。
            <br />
          </CustomText>
        </Box>
        <Box ref={message3Ref} className="fade_in">
          <CustomText
            id="message3"
            w="full"
            font="serif"
            fontStyle="italic"
            fontWeight="bold"
            textAlign="center"
            color="primary"
            size="lg"
            lineHeight="2.0"
          >
            迷えるあなたを、指南します。
          </CustomText>
          <Image
            alt="南島原市のロゴ"
            loading="lazy"
            mt="2%"
            mx="auto"
            w="25%"
            src="images/logo.png"
          />
        </Box>
      </Stack>
    </>
  );
};
