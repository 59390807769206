import { extendTheme } from "@chakra-ui/react";

const lightTheme = extendTheme({
  colors: {
    primary: "#143282",
    // primary: "rgb(20, 49, 130)",
    secondary: "#29a4e6",
    text: {
      body: "#1A1A1C",
      description: "#626264",
      placeholder: "#757578",
      onfill: "#FFFFFF",
      link: "#0017C1",
      hover: "#000082",
      active: "#000082",
      visited: "#000082",
      alert: "#EC0000",
      disabled: "#949497",
    },
    icon: {
      active: "#0017C1",
      disabled: "#949497",
      alert: "#EC0000",
      label: "#1A1A1C",
    },
    button: {
      normal: "#0017C1",
      active: "#000082",
      hover: "#000082",
      disabled: "#949497",
    },
    background: {
      primary: "#FFFFFF",
      // primary: "rgba(46, 182, 255, 0.1)",
      secondary: "#FFFFFF",
      tertiary: "#F8F8FB",
    },
    border: {
      field: "#1A1A1C",
      divider: "#D8D8DB",
      focused: "#D18D0F",
      selected: "#0017C1",
      alert: "#EC0000",
      disabled: "#949497",
    },
    "ui-status": {
      success: "#259D63",
      alert: "#EC0000",
      warning: "#C16800",
    },
    chart: {
      primary: "#0017C1",
      secondary: "#4979F5",
    },
  },
});

export default lightTheme;
