import App from "@/App";
import reportWebVitals from "@/reportWebVitals";
import lightTheme from "@/utils/ColorThemeLight";
import { ChakraProvider } from "@chakra-ui/react";
import "@css/index.css";
import React from "react";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={lightTheme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
);

reportWebVitals();
