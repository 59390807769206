import "@css/footer.css";

export const Footer = () => {
  return (
    <div id="footer_area">
      <div className="footer_info">
        <div className="toiawase">
          <div className="toiawase_left">
            <div className="footerimg">
              <img
                loading="lazy"
                src="//www.city.minamishimabara.lg.jp/dynamic/common/images/top1/foter_title.png"
                alt="長崎県南島原市"
                title=""
              />
              <div className="houjin">法人番号 7000020422142</div>
            </div>

            <div className="add">
              <p>
                〒859-2211&nbsp; 長崎県南島原市西有家町里坊96番地2
                <br />
                電話番号:
                <span className="tel">
                  <span
                    className="teltoon"
                    style={{ display: "inline-block;" }}
                  >
                    <a href="tel:0957-73-6600">0957-73-6600</a>
                  </span>
                </span>
                &nbsp;&nbsp; &nbsp;&nbsp;
                <svg
                  width="16px"
                  height="16px"
                  className="svg-inline--fa fa-envelope fa-w-16 my-white fa-fw"
                  aria-hidden="true"
                  data-prefix="fas"
                  data-icon="envelope"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-fa-i2svg=""
                  style={{
                    padding: "3px 0 0 0 ",
                    fontSize: "10px",
                    display: "inline-block",
                  }}
                >
                  <path
                    fill="white"
                    d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                  ></path>
                </svg>
                &nbsp;&nbsp;info@city.minamishimabara.lg.jp
                <br />
                <span className="text_s">
                  ※Eメールでのお問い合わせの際は、「@city.minamishimabara.lg.jp」のドメインを受信できるように設定をお願いします。{" "}
                  <br />
                  〔開庁時間〕月曜～金曜の午前8時30分～午後5時15分（ただし、祝・休日、12月29日～翌年1月3日を除く）{" "}
                </span>
              </p>
            </div>
          </div>
          <div className="toiawase_right">
            <img
              loading="lazy"
              src="//www.city.minamishimabara.lg.jp/dynamic/common/images/top1/map.png"
              alt="南島原市マップ"
              title=""
            />
          </div>
        </div>
        <div className="clear"></div>

        <div className="footer_Menu">
          <div className="footer_Menu_item">
            <ul>
              <li>
                <a href="https://www.city.minamishimabara.lg.jp/default.html">
                  ホームページ
                </a>
              </li>
              <li className="last">
                <a href="//www.city.minamishimabara.lg.jp/kiji0034407/index.html">
                  プライバシーポリシー
                </a>
              </li>
            </ul>
          </div>
          <div className="clear"></div>
        </div>

        <div className="clear"></div>
      </div>

      <div className="clear"></div>
      <div id="cp" lang="en">
        Copyrights(c)2023 Minamishimabara City. All Rights Reserved
      </div>
    </div>
  );
};
