import CustomText from "@/components/elements/CustomText";
import { Box, Image } from "@chakra-ui/react";
import { useState } from "react";
import YouTube from "react-youtube";

type YoutubeProps = {
  id: string;
  w: number | string;
  title: string;
  thumbnail?: string;
  isOutText?: boolean;
  isMute?: boolean;
};

export const LiteYoutubePlayer: React.FC<YoutubeProps> = ({
  id,
  w,
  title,
  thumbnail,
  isOutText = false,
  isMute = false,
}) => {
  const [isClicked, setIsClicked] = useState(true);

  return (
    <>
      {isClicked ? (
        <Box w={w} mx="auto" cursor="pointer" overflow="hidden">
          <Box position="relative">
            {!isOutText && (
              <CustomText
                pr="4%"
                width="100%"
                size="sm"
                top="5%"
                left="2%"
                color="background.primary"
                position="absolute"
                isTruncated
              >
                {title}
              </CustomText>
            )}
            <Image
              width="100%"
              mx="auto"
              loading="lazy"
              aspectRatio="64 / 35"
              src={
                thumbnail
                  ? thumbnail
                  : `https://img.youtube.com/vi/${id}/maxresdefault.jpg`
              }
              onClick={() => setIsClicked(false)}
              alt="サムネイル"
            />
            <Image
              loading="lazy"
              w="12%"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              src="images/youtube_button.png"
              onClick={() => setIsClicked(false)}
              alt="再生ボタン"
            />
          </Box>

          {isOutText && (
            <CustomText
              py="2%"
              width="100%"
              size="lg"
              font="serif"
              color="text.onfill"
              fontWeight="bold"
              textAlign="center"
              isTruncated
            >
              {title}
            </CustomText>
          )}
        </Box>
      ) : (
        <Box w={w} mx="auto">
          <YouTube
            videoId={`${id}`}
            opts={{
              width: "100%",
              height: "100%",
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                loop: 1,
                autoplay: 1,
                mute: isMute ? 1 : 0,
                rel: 0,
                playlist: `${id}`,
              },
            }}
            style={{ aspectRatio: "64 / 35", margin: "auto" }}
            // onStateChange={(event) => {
            //   setPlayerState(event.data);
            // }}
          />
          {isOutText && (
            <CustomText
              py="2%"
              width="100%"
              size="lg"
              font="serif"
              color="text.onfill"
              fontWeight="bold"
              textAlign="center"
              isTruncated
            >
              {title}
            </CustomText>
          )}
        </Box>
      )}
    </>
  );
};
