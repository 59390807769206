import { ITEM_DATA } from "@/components/slot/SlotDatas";
import ReelNumbers from "@/components/slot/slotMachine/ReelNumbers";
import { CERTIFIED_ITEM } from "@/utils/RandomPicker";
import { wait } from "@testing-library/user-event/dist/utils";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import styled from "styled-components";

type SlotProps = {
  items: Array<CERTIFIED_ITEM>;
  pickedNum: number;
  isRotating: boolean;
  setIsWaitng: Dispatch<SetStateAction<boolean>>;
  setIsDisplayResult: Dispatch<SetStateAction<boolean>>;
  setIsRotting: Dispatch<SetStateAction<boolean>>;
};

export const SlotMachine: React.FC<SlotProps> = ({
  items,
  pickedNum,
  isRotating,
  setIsWaitng,
  setIsDisplayResult,
  setIsRotting,
}) => {
  const keys = Object.keys(ITEM_DATA);
  const [currentNumber, setCurrentNumber] = useState(1);
  const isFirstRender = useRef(false);

  useEffect(() => {
    isFirstRender.current = true;
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    let interval: NodeJS.Timeout;

    if (isRotating) {
      interval = setInterval(() => {
        setCurrentNumber((prevNumber) => (prevNumber % keys.length) + 1);
      }, 150);
    } else {
      let counter = 0;
      setIsWaitng(true);
      interval = setInterval(() => {
        if (counter < keys.length + pickedNum - currentNumber - 1) {
          setCurrentNumber((prevNumber) => (prevNumber % keys.length) + 1);
          counter++;
        } else {
          clearInterval(interval);
          (async () => {
            await wait(300);
            setIsWaitng(false);
            setIsRotting(false);
            setIsDisplayResult(true);
          })();
        }
      }, 250);
    }

    return () => clearInterval(interval);
  }, [isRotating]);

  return (
    <Wrapper>
      <ReelNumbers items={items} currentNumber={currentNumber} fps={2} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export default SlotMachine;
