import { useEffect, useState } from "react";

export const mediaQuery = {
  sp: "max-width: 426px",
  tablet: "(min-width: 427px) and (max-width: 834px)",
  pc: "min-width:835px",
};

export const useMediaQuery = (query: string) => {
  /**
   * const isSp = useCustomMediaQuery(mediaQuery.sp)
   * const isTablet = useCustomMediaQuery(mediaQuery.tablet)
   * const isPC = useCustomMediaQuery(mediaQuery.pc)
   */
  const formattedQuery = `(${query})`;
  const [match, setMatch] = useState(matchMedia(formattedQuery).matches);

  useEffect(() => {
    const mql = matchMedia(formattedQuery);

    if (mql.media === "not all" || mql.media === "invalid") {
      console.error(`useCustomMediaQuery Error: Invalid media query`);
    }

    mql.onchange = (e) => {
      setMatch(e.matches);
    };

    return () => {
      mql.onchange = null;
    };
  }, [formattedQuery, setMatch]);

  return match;
};
