export const ITEM_DATA: { [key: string]: string } = {
  "島原手延べ素麺「島原の白絲」": "https://hogehoge0",
  小林甚の長崎の麺アラカルト詰合せ: "https://hogehoge1",
  華豊お試しセット: "https://hogehoge2",
  華豊の炒飯: "https://hogehoge3",
  "辣子鶏（ラーズーチー）3パック": "https://hogehoge4",
  "本場の味！冷凍長崎ちゃんぽん6食": "https://hogehoge5",
  "車海老極上香味油 50ml": "https://hogehoge6",
  "長崎和牛ローストビーフ 300g": "https://hogehoge7",
  雲仙牧場鹿6点セット: "https://hogehoge8",
  おつまみジャーキー: "https://hogehoge9",
};
