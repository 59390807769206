export function RandomNumberPicker(size: number): number {
  const currentTime = new Date().getTime();
  return currentTime % size;
}

export type CERTIFIED_ITEM = {
  id: string;
  name: string;
  img_url: string;
  url: string;
};

export function RandomDataPicker(
  data: Array<CERTIFIED_ITEM>,
  size: number,
): Array<CERTIFIED_ITEM> {
  const indexArr = Array<number>();
  while (indexArr.length < size) {
    const randIndex = Math.floor(Math.random() * data.length);
    if (!indexArr.includes(randIndex)) indexArr.push(randIndex);
  }

  return indexArr.map((idx) => {
    return data[idx];
  });
}

export const CERTIFIED_ITEM_DATA = [
  {
    id: "no001",
    name: "どぶろく（夜のよして）",
    img_url: "no001.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no001",
  },
  {
    id: "no002",
    name: "ときめきみかんジュース　まるごと花子",
    img_url: "no002.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no002",
  },
  {
    id: "no005",
    name: "スープ生姜麺",
    img_url: "no005.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no005",
  },
  {
    id: "no006",
    name: "スープほうれん草めん",
    img_url: "no006.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no006",
  },
  {
    id: "no007",
    name: "島原薬草麺のスープカレーそうめん",
    img_url: "no007.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no007",
  },
  {
    id: "no009",
    name: "手延べそうめん",
    img_url: "no009.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no009",
  },
  {
    id: "no010",
    name: "手延べ黒ごま麺",
    img_url: "no010.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no010",
  },
  {
    id: "no011",
    name: "手延べひじき麺",
    img_url: "no011.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no011",
  },
  {
    id: "no012",
    name: "手延べわかめ麺",
    img_url: "no012.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no012",
  },
  {
    id: "no013",
    name: "手延べ紫いも麺",
    img_url: "no013.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no013",
  },
  {
    id: "no017",
    name: "しいたけチップス",
    img_url: "no017.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no017",
  },
  {
    id: "no018",
    name: "しいたけパウダー",
    img_url: "no018.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no018",
  },
  {
    id: "no020",
    name: "おかわりさん",
    img_url: "no020.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no020",
  },
  {
    id: "no021",
    name: "あごだし味噌「飛翔」",
    img_url: "no021.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no021",
  },
  {
    id: "no022",
    name: "あごだし",
    img_url: "no022.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no022",
  },
  {
    id: "no023",
    name: "島原手延べ　生素麺",
    img_url: "no023.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no023",
  },
  {
    id: "no024",
    name: "とら巻",
    img_url: "no024.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no024",
  },
  {
    id: "no025",
    name: "長崎・島原の伝統製法　手延べそうめん",
    img_url: "no025.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no025",
  },
  {
    id: "no027",
    name: "手延べ黒ごま素麺",
    img_url: "no027.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no027",
  },
  {
    id: "no028",
    name: "手延べ白ごま素麺",
    img_url: "no028.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no028",
  },
  {
    id: "no032",
    name: "あげとっと（プレーン塩）",
    img_url: "no032.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no032",
  },
  {
    id: "no033",
    name: "あげとっと（チーズ胡椒）",
    img_url: "no033.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no033",
  },
  {
    id: "no034",
    name: "あげとっと（チリガーリック）",
    img_url: "no034.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no034",
  },
  {
    id: "no038",
    name: "長崎じげもん豚塩麹漬け",
    img_url: "no038.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no038",
  },
  {
    id: "no039-2",
    name: "雲仙しまばら鶏塩麹漬け",
    img_url: "no039-2.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/039-2",
  },
  {
    id: "no040",
    name: "長崎じげもん豚生姜みそ漬け",
    img_url: "no040.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no040",
  },
  {
    id: "no041",
    name: "長崎じげもん豚はちみつ味噌ハンバーグ",
    img_url: "no041.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no041",
  },
  {
    id: "no042",
    name: "手延べちゃんぽん3食（スープ付）",
    img_url: "no042.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no042",
  },
  {
    id: "no045",
    name: "翡翠色銀杏（薄皮なし冷凍・殻付冷凍）",
    img_url: "no045.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no045",
  },
  {
    id: "no046",
    name: "田舎造り合わせみそ",
    img_url: "no046.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no046",
  },
  {
    id: "no047",
    name: "昔造り醤油",
    img_url: "no047.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no047",
  },
  {
    id: "no048",
    name: "イチョウの木のまな板",
    img_url: "no048.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no048",
  },
  {
    id: "no049",
    name: "肉厚生椎茸ギフトセット",
    img_url: "no049.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no049",
  },
  {
    id: "no050",
    name: "バリアフリートイレットペーパーホルダー",
    img_url: "no050.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no050",
  },
  {
    id: "no052",
    name: "特別栽培馬鈴薯",
    img_url: "no052.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no052",
  },
  {
    id: "no054",
    name: "特別栽培玉ねぎ",
    img_url: "no054.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no054",
  },
  {
    id: "no055",
    name: "特別栽培温州みかん",
    img_url: "no055.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no055",
  },
  {
    id: "no058",
    name: "特別栽培トマト",
    img_url: "no058.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no058",
  },
  {
    id: "no060",
    name: "早崎瀬戸あらかぶ",
    img_url: "no060.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no060",
  },
  {
    id: "no061",
    name: "島原半島はしり蛸",
    img_url: "no061.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no061",
  },
  {
    id: "no063",
    name: "原城丸ボーロ",
    img_url: "no063.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no063",
  },
  {
    id: "no064",
    name: "レモンケーキ",
    img_url: "no064.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no064",
  },
  {
    id: "no065",
    name: "長崎カステラ",
    img_url: "no065.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no065",
  },
  {
    id: "no066",
    name: "和三盆糖 長崎五三焼カステラ",
    img_url: "no066.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no066",
  },
  {
    id: "no067",
    name: "ジャム（イチジク・イチゴ）二種類",
    img_url: "no067.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no067",
  },
  {
    id: "no068",
    name: "有家梨",
    img_url: "no068.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no068",
  },
  {
    id: "no069",
    name: "わかめ麺　ひょっつる",
    img_url: "no069.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no069",
  },
  {
    id: "no070",
    name: "塩蔵わかめ",
    img_url: "no070.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no070",
  },
  {
    id: "no071",
    name: "野生酵母柿酢",
    img_url: "no071.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no071",
  },
  {
    id: "no072",
    name: "中邨の白露柿",
    img_url: "no072.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no072",
  },
  {
    id: "no075",
    name: "手延べ素麺",
    img_url: "no075.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no075",
  },
  {
    id: "no076",
    name: "手延べ冷麦",
    img_url: "no076.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no076",
  },
  {
    id: "no077",
    name: "手延べ饂飩",
    img_url: "no077.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no077",
  },
  {
    id: "no078",
    name: "手延べ蕎麦",
    img_url: "no078.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no078",
  },
  {
    id: "no079",
    name: "長崎中華麺　匠（手延べらーめん2人前、とんこつスープ付）",
    img_url: "no079.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no079",
  },
  {
    id: "no080",
    name: "長崎中華麺　匠（手延べちゃんぽん2人前、ちゃんぽんスープ付）",
    img_url: "no080.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no080",
  },
  {
    id: "no092",
    name: "本格麦焼酎　青一髪",
    img_url: "no092.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no092",
  },
  {
    id: "no096",
    name: "原城とまと",
    img_url: "no096.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no096",
  },
  {
    id: "no102",
    name: "いちじくジャム・桃ジャム",
    img_url: "no102.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no102",
  },
  {
    id: "no108",
    name: "精肉加工品（和牛）",
    img_url: "no108.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no108",
  },
  {
    id: "no118",
    name: "養殖・活車えび",
    img_url: "no118.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no118",
  },
  {
    id: "no119",
    name: "力みそ",
    img_url: "no119.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no119",
  },
  {
    id: "no120",
    name: "島原納豆みそ",
    img_url: "no120.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no120",
  },
  {
    id: "no121",
    name: "うまいんで酢",
    img_url: "no121.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no121",
  },
  {
    id: "no122",
    name: "スプーンdeみそ汁",
    img_url: "no122.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no122",
  },
  {
    id: "no123",
    name: "ロザリオ南蛮パスタ",
    img_url: "no123.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no123",
  },
  {
    id: "no124",
    name: "ロザリオ南蛮パスタ PN-10",
    img_url: "no124.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no124",
  },
  {
    id: "no125",
    name: "手延パスタ島原洋麺",
    img_url: "no125.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no125",
  },
  {
    id: "no143",
    name: "南高みかん",
    img_url: "no143.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no143",
  },
  {
    id: "no144",
    name: "ぽんかん",
    img_url: "no144.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no144",
  },
  {
    id: "no145",
    name: "不知火",
    img_url: "no145.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no145",
  },
  {
    id: "no147",
    name: "島原手延素麺（紅白素麺）",
    img_url: "no147.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no147",
  },
  {
    id: "no148",
    name: "島原手延素麺（梅素麺）",
    img_url: "no148.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no148",
  },
  {
    id: "no149",
    name: "文ちゃんの麺つゆ（甘口）",
    img_url: "no149.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no149",
  },
  {
    id: "no151",
    name: "文ちゃんの麺つゆ（中口）",
    img_url: "no151.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no151",
  },
  {
    id: "no152",
    name: "かまぼこ店の和風五目コロッケ(南蛮コロッケ)",
    img_url: "no152.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no152",
  },
  {
    id: "no153",
    name: "ひろす（とうふ蒲鉾）",
    img_url: "no153.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no153",
  },
  {
    id: "no154",
    name: "いわし半月天",
    img_url: "no154.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no154",
  },
  {
    id: "no155",
    name: "とうふ蒲鉾　長寿村",
    img_url: "no155.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no155",
  },
  {
    id: "no156",
    name: "いぎりす風蒲鉾　ジパング",
    img_url: "no156.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no156",
  },
  {
    id: "no157",
    name: "島原わかめ蒲鉾",
    img_url: "no157.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no157",
  },
  {
    id: "no158",
    name: "熟成じゃがいも",
    img_url: "no158.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no158",
  },
  {
    id: "no165",
    name: "手延素麺　島原の細糸",
    img_url: "no165.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no165",
  },
  {
    id: "no166",
    name: "全粒粉入り手延そうめん島原小町",
    img_url: "no166.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no166",
  },
  {
    id: "no167",
    name: "島原手延うどん島原のれん",
    img_url: "no167.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no167",
  },
  {
    id: "no168",
    name: "島原手延べそうめん九州小麦　KY-50",
    img_url: "no168.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no168",
  },
  {
    id: "no169",
    name: "島原伝統手延べ　素麺",
    img_url: "no169.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no169",
  },
  {
    id: "no170",
    name: "島原伝統手延べ　梅素麺",
    img_url: "no170.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no170",
  },
  {
    id: "no171",
    name: "島原手延べ　五穀素麺",
    img_url: "no171.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no171",
  },
  {
    id: "no172",
    name: "島原手延べそうめんトマトバジルそうめん",
    img_url: "no172.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no172",
  },
  {
    id: "no173",
    name: "島原手延べまぜ麺",
    img_url: "no173.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no173",
  },
  {
    id: "no174",
    name: "【肉味噌】Miso de carne(みそでかるね）",
    img_url: "no174.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no174",
  },
  {
    id: "no175",
    name: "雲仙しまばら鶏生姜みそ漬け",
    img_url: "no175.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no175",
  },
  {
    id: "no176",
    name: "こだわり手延素麺　山川の糸",
    img_url: "no176.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no176",
  },
  {
    id: "no177",
    name: "楠田の手延べそうめん",
    img_url: "no177.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no177",
  },
  {
    id: "no178",
    name: "南果ジュース",
    img_url: "no178.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no178",
  },
  {
    id: "no179",
    name: "手のべ素麺冬季製造木箱入り限定品",
    img_url: "no179.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no179",
  },
  {
    id: "no180",
    name: "手延べ中華麺（冷やし中華・ラーメン・チャンポン）",
    img_url: "no180.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no180",
  },
  {
    id: "no181",
    name: "島原のがんこ麺、島原手延べそうめん",
    img_url: "no181.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no181",
  },
  {
    id: "no182",
    name: "車えびトロSURIMIIしゅうまい",
    img_url: "no182.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no182",
  },
  {
    id: "no183",
    name: "車えび塩",
    img_url: "no183.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no183",
  },
  {
    id: "no184",
    name: "深江町漁協車えびの塩糀漬け",
    img_url: "no184.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no184",
  },
  {
    id: "no185",
    name: "深江町漁協車えびの西京漬け",
    img_url: "no185.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no185",
  },
  {
    id: "no186",
    name: "南島原産　天然とらふぐの刺身4人前",
    img_url: "no186.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no186",
  },
  {
    id: "no187",
    name: "長崎県産とらふぐの刺身",
    img_url: "no187.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no187",
  },
  {
    id: "no188",
    name: "明日の果実（マンゴー、マスカット）",
    img_url: "no188.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no188",
  },
  {
    id: "no189",
    name: "島原手延べ素麺「手延べ日和」",
    img_url: "no189.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no189",
  },
  {
    id: "no190",
    name: "島原手延べうどん「手延べ日和」",
    img_url: "no190.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no190",
  },
  {
    id: "no191",
    name: "車海老香味油",
    img_url: "no191.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no191",
  },
  {
    id: "no192",
    name: "南島原の料理人が作った　極上めんつゆ",
    img_url: "no192.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no192",
  },
  {
    id: "no193",
    name: "なかなかバナナ",
    img_url: "no193.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no193",
  },
  {
    id: "no194",
    name: "レモンケーキ",
    img_url: "no194.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no194",
  },
  {
    id: "no195",
    name: "アスパラプリン",
    img_url: "no195.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no195",
  },
  {
    id: "no196",
    name: "とうふ蒲鉾６本入",
    img_url: "no196.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no196",
  },
  {
    id: "no197",
    name: "長崎海鮮かまぼこ",
    img_url: "no197.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no197",
  },
  {
    id: "no198",
    name: "薬膳ヘルシーかまぼこ24",
    img_url: "no198.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no198",
  },
  {
    id: "no199",
    name: "島原手延べ素麺",
    img_url: "no199.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no199",
  },
  {
    id: "no200",
    name: "島原手延べうどん",
    img_url: "no200.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no200",
  },
  {
    id: "no201",
    name: "黒にんにく",
    img_url: "no201.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no201",
  },
  {
    id: "no202",
    name: "柚子胡椒",
    img_url: "no202.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no202",
  },
  {
    id: "no203",
    name: "手延べそうめん川上の糸",
    img_url: "no203.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no203",
  },
  {
    id: "no204",
    name: "木製スツール　「おすわりくん」",
    img_url: "no204.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no204",
  },
  {
    id: "no205",
    name: "雲仙ごま　煎り黒ごま",
    img_url: "no205.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no205",
  },
  {
    id: "no206",
    name: "雲仙ごま　煎り白ごま",
    img_url: "no206.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no206",
  },
  {
    id: "no207",
    name: "手延べ黒ごま麺「豊穣」",
    img_url: "no207.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no207",
  },
  {
    id: "no208",
    name: "レモン塩だれ",
    img_url: "no208.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no208",
  },
  {
    id: "no209",
    name: "花子",
    img_url: "no209.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no209",
  },
  {
    id: "no210",
    name: "みなみ",
    img_url: "no210.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no210",
  },
  {
    id: "no211",
    name: "手延べッティショートタイプ",
    img_url: "no211.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no211",
  },
  {
    id: "no212",
    name: "スイートアスパラ",
    img_url: "no212.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no212",
  },
  {
    id: "no213",
    name: "活〆幸いか",
    img_url: "no213.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no213",
  },
  {
    id: "no214",
    name: "島原レモン麺",
    img_url: "no214.webp",
    url: "https://oishii-minamishimabara.jp/certified_product/no214",
  },
];
