import CustomText from "@/components/elements/CustomText";
import { ITEM_DATA } from "@/components/slot/SlotDatas";
import SlotMachine from "@/components/slot/slotMachine/SlotMachine";
import {
  CERTIFIED_ITEM,
  CERTIFIED_ITEM_DATA,
  RandomDataPicker,
  RandomNumberPicker,
} from "@/utils/RandomPicker";
import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { mediaQuery, useMediaQuery } from "@/utils/UseMediaQuery";
import "@css/button.css";

type SlotProps = {
  isComingSoon: boolean;
};

export const Slot: React.FC<SlotProps> = ({ isComingSoon }) => {
  const isPC = useMediaQuery(mediaQuery.pc);
  const isTablet = useMediaQuery(mediaQuery.tablet);

  const keys = Object.keys(ITEM_DATA);
  const [isRotating, setIsRotating] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isRotting, setIsRotting] = useState(false);
  const [pickedNum, setPickedNum] = useState(-1);
  const [isDisplayResult, setIsDisplayResult] = useState(false);

  const toggleRotating = () => {
    setIsRotating((p) => !p);
    setIsDisplayResult(false);
    setPickedNum(RandomNumberPicker(keys.length));
    if (!isRotating) {
      setIsRotting(true);
      setItemData(RandomDataPicker(CERTIFIED_ITEM_DATA, 10));
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setItemData(RandomDataPicker(CERTIFIED_ITEM_DATA, 10));
  }, []);

  useEffect(() => {
    if (isDisplayResult && pickedNum != -1) {
      onOpen();
    }
  }, [isDisplayResult]);

  const [itemData, setItemData] = useState(Array<CERTIFIED_ITEM>);

  return (
    <Box mt="-7%">
      {isComingSoon && (
        <>
          <Box w="full" position="relative">
            <Image
              loading="lazy"
              alt="美川さんのお取り寄せ指南"
              w="full"
              src={`images/${isPC ? "PC" : "Tablet"}/shinan.webp`}
            />
            <Box
              w="full"
              h="full"
              bg="blackAlpha.800"
              position="absolute"
              top="0"
            />
            <CustomText
              w="full"
              textAlign="center"
              color="text.onfill"
              font="serif"
              size="extra"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              fontWeight="bold"
              fontStyle="italic"
            >
              Coming Soon...!
            </CustomText>
          </Box>
        </>
      )}
      {!isComingSoon && (
        <>
          <Box w="full" position="relative">
            <Image
              loading="lazy"
              alt="美川さんのお取り寄せ指南"
              w="full"
              src={`images/PC/shinan.webp`}
            />
            <Box
              w="90%"
              position="absolute"
              bottom={`${
                isPC ? "calc(5% + 100px)" : isTablet ? "65px" : "40px"
              }`}
              left="50%"
              transform="translate(-50%, 50%)"
            >
              <SlotMachine
                {...{
                  items: itemData,
                  pickedNum: pickedNum,
                  isRotating: isRotating,
                  setIsWaitng: setIsWaiting,
                  setIsDisplayResult: setIsDisplayResult,
                  setIsRotting: setIsRotting,
                }}
              />
            </Box>
          </Box>
          <Box
            mt={isPC ? "80px" : isTablet ? "80px" : "60px"}
            mb={isPC ? "60px" : isTablet ? "60px" : "40px"}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Box
              mx="10%"
              position="relative"
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              <Button
                aria-label="スタートボタン"
                bg="transparent"
                position="absolute"
                left="50%"
                transform="translate(-50%, 0%)"
                className={`start_button ${isRotating ? "lotting" : ""}`}
                w={isPC ? "100px" : isTablet ? "80px" : "60px"}
                h={isPC ? "100px" : isTablet ? "80px" : "60px"}
                mx="auto"
                _hover={
                  isPC
                    ? !isWaiting
                      ? {
                          textColor: "white",
                          bg: "transparent",
                          _after: {
                            animation: "pulse 0.6s",
                            animationFillMode: "forwards",
                          },
                          _before: {
                            "background-color": "#29a4e6",
                            animation: "pop 0.6s",
                            animationFillMode: "forwards",
                          },
                        }
                      : {
                          textColor: "white",
                          bg: "transparent",
                        }
                    : {}
                }
                _focus={
                  !isPC
                    ? !isWaiting
                      ? {
                          textColor: "white",
                          bg: "transparent",
                          _after: {
                            animation: "pulse 0.6s",
                            animationFillMode: "forwards",
                          },
                          _before: {
                            "background-color": "#29a4e6",
                            animation: "pop 0.6s",
                            animationFillMode: "forwards",
                          },
                        }
                      : {
                          textColor: "white",
                          bg: "transparent",
                        }
                    : {}
                }
                _after={
                  !isRotting
                    ? {
                        content: `""`,
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        backgroundColor: "#29a4e6",
                        transform: "scale(0.1)",
                        opacity: 0,
                        border: "1px solid #29a4e6",
                        "border-radius": "100%",
                        "z-index": "-1",
                      }
                    : {
                        content: `""`,
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        border: "1px solid #29a4e6",
                        "border-radius": "100%",
                        "z-index": "-1",
                        backgroundColor: "#29a4e6",
                      }
                }
                _before={{
                  content: `""`,
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  border: "1px solid #29a4e6",
                  "border-radius": "100%",
                  backgroundColor: "#fff",
                  "z-index": "-1",
                }}
                isDisabled={isWaiting}
                onClick={toggleRotating}
                role="group"
              >
                <CustomText
                  transition="0.5s"
                  font="serif"
                  color={isRotting ? "text.onfill" : "text.body"}
                  _groupHover={
                    isRotting
                      ? {}
                      : {
                          textColor: "white",
                          bg: "transparent",
                          animation: "popup 0.5s forwards",
                        }
                  }
                >
                  {!isRotting ? "Start" : "Stop"}
                </CustomText>
              </Button>
            </Box>
          </Box>
        </>
      )}

      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent m="auto" position="relative" zIndex="100000">
          <ModalCloseButton />
          <ModalHeader mt="2%">
            <CustomText size="lg" font="serif">
              &nbsp;&nbsp;&nbsp;&nbsp;指南された商品
            </CustomText>
          </ModalHeader>
          <ModalBody overflow="hidden">
            <Box w="full" mt="5%" pb="10%" position="relative">
              <Image
                alt={
                  pickedNum >= 0 ? itemData[pickedNum].name : "指南された商品"
                }
                loading="eager"
                w="90%"
                mx="auto"
                src={
                  pickedNum >= 0
                    ? `images/${isPC ? "PC" : "Tablet"}/itemsLarge/${
                        itemData[pickedNum].img_url
                      }`
                    : ""
                }
              />
              <Box w="full" h="full" position="absolute" zIndex="10" top="-10%">
                <Image
                  loading="lazy"
                  alt="枠"
                  w="full"
                  h="full"
                  src={`images/${
                    isPC ? "PC" : isTablet ? "Tablet" : "SP"
                  }/waku.webp`}
                />
              </Box>
              {/* <Box
                visibility={isDisplayResult ? "visible" : "hidden"}
                w="25%"
                position="absolute"
                bottom="0"
                zIndex="10"
              >
                <Image 
 loading="lazy" src="images/cracker.gif" />
              </Box> */}
            </Box>
            <CustomText
              className="flowing"
              size="lg"
              font="serif"
              fontWeight="bold"
              textAlign="center"
              position="relative"
              _before={{
                content: `""`,
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 100%);",
                position: "absolute",
                top: "0",
              }}
            >
              {pickedNum >= 0 ? itemData[pickedNum].name : ""}
            </CustomText>
          </ModalBody>
          <ModalFooter>
            <Box mb="3%" w="full" textAlign="center">
              <a
                href={pickedNum >= 0 ? itemData[pickedNum].url : "#"}
                target="_blank"
                className="btn-square-slant"
                rel="noreferrer"
              >
                <CustomText
                  textAlign="center"
                  size="md"
                  font="serif"
                  color="text.onfill"
                  position="relative"
                  _after={{
                    content: `"▶"`,
                    fontSize: `${isPC ? "14px" : isTablet ? "12px" : "10px"}`,
                    position: "absolute",
                    top: "50%",
                    right: "0",
                    transform: "translate(200%, -50%)",
                  }}
                >
                  サイトで商品を見る
                </CustomText>
              </a>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
