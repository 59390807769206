import { fontSizes } from "@/utils/FontSizeConfig";
import { mediaQuery, useMediaQuery } from "@/utils/UseMediaQuery";
import { Text, TextProps } from "@chakra-ui/react";

export interface CustomTextProps extends TextProps {
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "extra";
  font?: "shippori" | "serif";
  isLink?: boolean;
}

function mapSizeToFontSize(
  size: CustomTextProps["size"],
  isSP: boolean,
  isTablet: boolean,
): string {
  switch (size) {
    case "xs":
      return fontSizes(isSP, isTablet).xs;
    case "sm":
      return fontSizes(isSP, isTablet).sm;
    case "md":
      return fontSizes(isSP, isTablet).md;
    case "lg":
      return fontSizes(isSP, isTablet).lg;
    case "xl":
      return fontSizes(isSP, isTablet).xl;
    case "xxl":
      return fontSizes(isSP, isTablet).xxl;
    case "extra":
      return fontSizes(isSP, isTablet).extra;
    default:
      return fontSizes(isSP, isTablet).md;
  }
}

const mapFontToFontFamily = (font?: CustomTextProps["font"]): string => {
  switch (font) {
    case "shippori":
      return "'Shippori Mincho', serif";

    case "serif":
      return "'Noto Serif JP', serif";
    default:
      return "'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Kaku Gothic ProN', 'BIZ UDPGothic', Meiryo, sans-serif";
  }
};

const CustomText: React.FC<CustomTextProps> = ({
  size,
  font,
  isLink = false,
  ...rest
}) => {
  const isSP = useMediaQuery(mediaQuery.sp);
  const isTablet = useMediaQuery(mediaQuery.tablet);
  const fontSize = mapSizeToFontSize(size, isSP, isTablet);
  const fontFamily = mapFontToFontFamily(font);
  return (
    <Text
      fontSize={fontSize}
      fontFamily={fontFamily}
      color="text.body"
      textDecoration={isLink ? "underline" : ""}
      _hover={isLink ? { color: "text.link" } : {}}
      {...rest}
    />
  );
};

export default CustomText;
