import { Footer } from "@/components/Footer";
import { ItemViewer } from "@/components/itemViewer/ItemViewer";
import { Map } from "@/components/map/Map";
import { News } from "@/components/news/News";
import { Slot } from "@/components/slot/Slot";
import { Top } from "@/components/top/Top";
import { TopImageViewer } from "@/components/top/TopImageViewer";
import { Youtube } from "@/components/youtube/Youtube";
import { mediaQuery, useMediaQuery } from "@/utils/UseMediaQuery";
import { Box } from "@chakra-ui/react";
import { useEffect } from "react";

function App() {
  const isPC = useMediaQuery(mediaQuery.pc);
  const isTablet = useMediaQuery(mediaQuery.tablet);
  const isComingSoon = false;

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);
  return (
    <Box className="App" bg="background.primary" w="full" overflow="hidden">
      <Top />
      <TopImageViewer />
      <News />
      <Box
        bgImage={`images/${isPC ? "PC" : isTablet ? "Tablet" : "SP"}/bg2.webp`}
        bgPosition="top"
        backgroundSize={isPC ? "110%" : "cover"}
      >
        <Youtube />
        <Map />
        <Slot isComingSoon={isComingSoon} />
        <ItemViewer isComingSoon={isComingSoon} />
      </Box>
      <Footer />
    </Box>
  );
}

export default App;
