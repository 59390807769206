import { mediaQuery, useMediaQuery } from "@/utils/UseMediaQuery";
import { Box, Image } from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
export const TopImageViewer = () => {
  const isPC = useMediaQuery(mediaQuery.pc);

  const options = {
    fixedWidth: `${isPC ? 25 : 40}%`,
    type: "loop",
    speed: 0.5,
    drag: false,
    flickPower: 300,
    arrows: false,
    gap: "5%",
    pagination: false,
    lazyLoad: true,
    autoScroll: {
      speed: 0.5,
      pauseOnHover: false,
      pauseOnFocus: false,
      preventInteractionOnTransition: true,
    },
  };

  return (
    <Box bg="white" py="3%" position="relative" zIndex="20">
      <Box w="full" h="full" position="absolute" top="0" />
      <Splide options={options} extensions={{ AutoScroll }}>
        <SplideSlide>
          <Image
            src={`images/${isPC ? "PC" : "Tablet"}/top/menkui.webp`}
            alt="そうめん"
            display="inline-block"
          />
        </SplideSlide>
        <SplideSlide>
          <Image
            loading="lazy"
            src={`images/${isPC ? "PC" : "Tablet"}/top/yuukyo.webp`}
            alt="山の寺　邑居"
            display="inline-block"
          />
        </SplideSlide>
        <SplideSlide>
          <Image
            loading="lazy"
            src={`images/${isPC ? "PC" : "Tablet"}/top/michinoeki.webp`}
            alt="道の駅"
            display="inline-block"
          />
        </SplideSlide>
        <SplideSlide>
          <Image
            loading="lazy"
            src={`images/${isPC ? "PC" : "Tablet"}/top/chaya.webp`}
            alt="滝の茶屋"
            display="inline-block"
          />
        </SplideSlide>
        <SplideSlide>
          <Image
            loading="lazy"
            src={`images/${isPC ? "PC" : "Tablet"}/top/taki.webp`}
            alt="鮎帰りの滝"
            display="inline-block"
          />
        </SplideSlide>
        <SplideSlide>
          <Image
            loading="lazy"
            src={`images/${isPC ? "PC" : "Tablet"}/top/harajo.webp`}
            alt="原城跡"
            display="inline-block"
          />
        </SplideSlide>
        <SplideSlide>
          <Image
            loading="lazy"
            src={`images/${isPC ? "PC" : "Tablet"}/top/masago.webp`}
            alt="原城温泉　真砂"
            display="inline-block"
          />
        </SplideSlide>
        <SplideSlide>
          <Image
            loading="lazy"
            src={`images/${isPC ? "PC" : "Tablet"}/top/iruka.webp`}
            alt="いるか"
            display="inline-block"
          />
        </SplideSlide>
        <SplideSlide>
          <Image
            loading="lazy"
            src={`images/${isPC ? "PC" : "Tablet"}/top/maehama.webp`}
            alt="前浜海水浴場"
            display="inline-block"
          />
        </SplideSlide>
        <SplideSlide>
          <Image
            loading="lazy"
            src={`images/${isPC ? "PC" : "Tablet"}/top/tanada.webp`}
            alt="棚田"
            display="inline-block"
          />
        </SplideSlide>
      </Splide>
    </Box>
  );
};
