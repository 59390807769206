import CustomText from "@/components/elements/CustomText";
import { mediaQuery, useMediaQuery } from "@/utils/UseMediaQuery";
import { Box, Card, Image } from "@chakra-ui/react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
gsap.registerPlugin(ScrollTrigger);

export const Map = () => {
  const isPC = useMediaQuery(mediaQuery.pc);
  const isTablet = useMediaQuery(mediaQuery.tablet);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    setAnimation();
  }, []);

  const mapRef = useRef<HTMLDivElement>(null);

  const setAnimation = () => {
    gsap.to("#map", {
      scrollTrigger: {
        trigger: "#map",
        start: "top 70%",
        end: "bottom bottom",
        markers: false,
        onEnter: () => {
          mapRef?.current?.classList.add("active");
        },
      },
    });
  };

  const positions = [
    {
      top: 4 + 10,
      left: 79,
      disTop: [22 + 10, 27 + 10, 27 + 10],
      disLeft: [70, 70, 68],
      place: "道の駅ひまわり",
      src: "michinoeki",
      url: "https://www.google.com/maps/place/%E9%81%93%E3%81%AE%E9%A7%85+%E3%81%B2%E3%81%BE%E3%82%8F%E3%82%8A/@32.7395709,130.3648307,17z/data=!3m1!4b1!4m6!3m5!1s0x35406d94815f9b35:0x111c9f5b83c41454!8m2!3d32.7395664!4d130.3674056!16s%2Fg%2F11ggzlkbx_?entry=ttu",
    },
    {
      top: 3 + 10,
      left: 70,
      disTop: [-5 + 10, 27 + 10, 27 + 10],
      disLeft: [35, 70, 68],
      place: "山の寺 邑居\nそうめん流し",
      src: "yuukyo",
      url: "https://www.google.co.jp/maps/place/%E5%B1%B1%E3%81%AE%E5%AF%BA+%E9%82%91%E5%B1%85(%E3%82%86%E3%81%86%E3%81%8D%E3%82%87)/@32.7402376,130.3112805,17z/data=!3m1!4b1!4m6!3m5!1s0x354072ac1f8fe319:0xe3ba34653312ffe5!8m2!3d32.7402331!4d130.3138554!16s%2Fg%2F1th19gvf?hl=ja&entry=ttu",
    },
    {
      top: 10 + 10,
      left: 70,
      disTop: [22 + 10, 27 + 10, 27 + 10],
      disLeft: [70, 70, 68],
      place: "内野水源",
      src: "uchino",
      url: "https://goo.gl/maps/h13ixHg2wKEGkAnP9",
    },
    {
      top: 15 + 10,
      left: 60,
      disTop: [0 + 10, 0 + 10, 0 + 10],
      disLeft: [27, 13, 13],
      place: "鮎帰りの滝",
      src: "taki",
      url: "https://www.google.com/maps/place/%E9%AE%8E%E5%B8%B0%E3%82%8A%E3%81%AE%E6%BB%9D/@32.7060936,130.2948572,17z/data=!3m1!4b1!4m6!3m5!1s0x35400cdabe088709:0xae6bd896f426de87!8m2!3d32.7060891!4d130.2974321!16s%2Fg%2F11c51r9zys?entry=ttu",
    },
    {
      top: 17 + 10,
      left: 51,
      disTop: [0 + 10, 0 + 10, 0 + 10],
      disLeft: [17, 13, 13],
      place: "戸ノ隅の滝",
      src: "tonosumi",
      url: "https://goo.gl/maps/Vjz4rE5HvWCHrbVJ9",
    },
    {
      top: 22 + 10,
      left: 48,
      disTop: [17 + 10, 10 + 10, 13 + 10],
      disLeft: [17, 5, 10],
      place: "有馬湧水",
      src: "arima",
      url: "https://goo.gl/maps/Xavk2icThwB8qkkMA",
    },
    {
      top: 30 + 10,
      left: 59,
      disTop: [42 + 10, 42 + 10, 42 + 10],
      disLeft: [65, 65, 65],
      place: "面喰い",
      src: "menkui",
      url: "https://www.google.co.jp/maps/place/%E9%9D%A2%E5%96%B0%E3%81%84/@32.6599491,130.2968958,19.2z/data=!4m6!3m5!1s0x35400b47b0c30291:0x202fc2f96daf7a62!8m2!3d32.659902!4d130.296983!16s%2Fg%2F1thqdjj2?hl=ja&entry=ttu",
    },

    {
      top: 40 + 8,
      left: 49,
      disTop: [50 + 10, 50 + 10, 50 + 10],
      disLeft: [55, 55, 55],
      place: "原城跡",
      src: "harajo",
      url: "https://www.google.com/maps/place/%E5%8E%9F%E5%9F%8E%E8%B7%A1/@32.6392358,130.2269212,12.77z/data=!4m6!3m5!1s0x35400a6ef468c5c5:0xfbad0cd541385383!8m2!3d32.6290242!4d130.2541749!16s%2Fg%2F1vm_vnjn?entry=ttu",
    },

    {
      top: 39 + 8,
      left: 41,
      disTop: [20 + 10, 15 + 10, 15 + 10],
      disLeft: [10, 5, 5],
      place: "谷水棚田",
      src: "tanada",
      url: "https://www.google.com/maps/place/%E8%B0%B7%E6%B0%B4%E6%A3%9A%E7%94%B0/@32.6313925,130.2199497,17z/data=!3m1!4b1!4m6!3m5!1s0x354009fc2a0e5955:0xb2448befa6af8d48!8m2!3d32.631388!4d130.2225246!16s%2Fg%2F11bvt5g_t8?entry=ttu",
    },
    {
      top: 55 + 5,
      left: 29,
      disTop: [55 + 10, 55 + 10, 55 + 10],
      disLeft: [40, 40, 40],
      place: "イルカウォッチング",
      src: "iruka",
      url: "https://www.city.minamishimabara.lg.jp/kiji0031610/index.html",
    },
    {
      top: 50 + 5,
      left: 27,
      disTop: [60, 60 + 10, 70 + 10],
      disLeft: [-5, -8, 0],
      place: "白浜海水浴場",
      src: "shirahama",
      url: "https://goo.gl/maps/zgZCEfHrkhqeFJLh8",
    },
    {
      top: 43 + 5,
      left: 25,
      disTop: [18 + 10, 15 + 10, 13 + 10],
      disLeft: [0, 0, 3],
      place: "前浜海水浴場",
      src: "maehama",
      url: "https://www.google.co.jp/maps/place/%E5%89%8D%E6%B5%9C%E6%B5%B7%E6%B0%B4%E6%B5%B4%E5%A0%B4/@32.6224811,130.1652623,16.35z/data=!4m6!3m5!1s0x353ff7303cb38993:0x250927d27ac5fdfa!8m2!3d32.62343!4d130.1661051!16s%2Fg%2F11nmr1ywtc?hl=ja&entry=ttu",
    },
  ];
  const [actives, setActives] = useState(
    Array<boolean>(positions.length + 1).fill(false),
  );

  const changeActiveState = (idx: number) => {
    setActives(
      actives.map((active, i) => {
        return i === idx ? (!isPC ? !active : true) : false;
      }),
    );
  };

  return (
    <>
      <Box
        w="full"
        pb="7%"
        bg="linear-gradient(90deg, rgba(170,207,228,1) 0%, rgba(171, 215, 236, 1) 25%, rgba(171, 215, 236, 1) 50%, rgba(171, 215, 236, 1) 75%, rgba(142,180,213,1) 100%);"
      >
        <Box
          ref={mapRef}
          id="map"
          className="fade_in"
          w={isPC ? "60%" : isTablet ? "80%" : "100%"}
          mx="auto"
          justifyContent="center"
          position="relative"
          zIndex="100"
        >
          <Image
            w="full"
            src={`images/${isPC ? "PC" : "Tablet"}/map/map.webp`}
            alt="南島原のMAP"
            loading="lazy"
          />
          <Box
            w="40%"
            className={`compass ${isPC ? "pc" : "sp"} ${
              actives[positions.length] ? "clicked" : ""
            }`}
            onMouseEnter={() => {
              if (!isPC) return;
              changeActiveState(positions.length);
            }}
            style={{
              position: "absolute",
              top: `${isPC ? "-15%" : isTablet ? "-15%" : "-10%"} `,
              left: "-5%",
            }}
            onClick={() => {
              if (isPC) return;
              changeActiveState(positions.length);
            }}
          >
            <Image
              w="full"
              loading="lazy"
              alt="方位"
              src={`images/${isPC ? "PC" : "Tablet"}/map/compass.webp`}
            />
          </Box>
          {positions.map((data, idx) => {
            return (
              <>
                <Box
                  w={isPC ? "8%" : isTablet ? "9%" : "10%"}
                  className={`mikawa_face ${isPC ? "pc" : "sp"} ${
                    actives[idx] ? "clicked" : ""
                  }`}
                  onMouseEnter={() => {
                    if (!isPC) return;
                    changeActiveState(idx);
                  }}
                  style={{
                    position: "absolute",
                    top: `${data.top}%`,
                    left: `${data.left}%`,
                  }}
                  onClick={() => {
                    if (isPC) return;
                    changeActiveState(idx);
                  }}
                >
                  <Image
                    loading="lazy"
                    alt="美川さんの顔"
                    src={`images/${
                      isPC ? "PC" : "Tablet"
                    }/mikawa/mikawa_face.webp`}
                  />
                </Box>
                <Card
                  w={isPC ? "30%" : isTablet ? "35%" : "35%"}
                  bg="whiteAlpha.900"
                  boxShadow="false"
                  position="absolute"
                  top={`${data.disTop[isPC ? 0 : isTablet ? 1 : 2]}%`}
                  left={`${data.disLeft[isPC ? 0 : isTablet ? 1 : 2]}%`}
                  className={`pos_image ${!isPC ? "sp" : "pc"} ${
                    actives[idx] ? "clicked" : ""
                  }`}
                >
                  <Card shadow="none" overflow="hidden" borderBottomRadius="0">
                    <Image
                      loading="lazy"
                      w="full"
                      mx="auto"
                      src={`images/${isPC ? "PC" : "Tablet"}/top/${
                        data.src
                      }.webp`}
                      alt={data.place}
                    />
                  </Card>
                  <CustomText
                    font="serif"
                    isLink={true}
                    fontSize={isPC ? "1.5vw" : isTablet ? "2.5vw" : "3vw"}
                    whiteSpace="pre"
                    textAlign="center"
                    py="1"
                  >
                    <a href={data.url} target="_blank" rel="noreferrer">
                      {data.place}
                    </a>
                  </CustomText>
                </Card>
                {data.place == "鮎帰りの滝" && (
                  <Card
                    w={isPC ? "30%" : isTablet ? "40%" : "35%"}
                    bg="whiteAlpha.900"
                    boxShadow="false"
                    position="absolute"
                    top={`${chayaData.disTop[isPC ? 0 : isTablet ? 1 : 2]}%`}
                    left={`${chayaData.disLeft[isPC ? 0 : isTablet ? 1 : 2]}%`}
                    className={`pos_image ${!isPC ? "sp" : "pc"} ${
                      actives[idx] ? "clicked" : ""
                    }`}
                  >
                    <Card
                      shadow="none"
                      overflow="hidden"
                      borderBottomRadius="0"
                    >
                      <Image
                        loading="lazy"
                        w="full"
                        mx="auto"
                        src={`images/${isPC ? "PC" : "Tablet"}/top/${
                          chayaData.src
                        }.webp`}
                        alt={chayaData.place}
                      />
                    </Card>
                    <CustomText
                      font="serif"
                      isLink={true}
                      fontSize={isPC ? "1.5vw" : isTablet ? "2.5vw" : "3vw"}
                      whiteSpace="pre"
                      textAlign="center"
                      py="1"
                    >
                      <a href={chayaData.url} target="_blank" rel="noreferrer">
                        {chayaData.place}
                      </a>
                    </CustomText>
                  </Card>
                )}
                {data.place == "原城跡" && (
                  <Card
                    w={isPC ? "30%" : isTablet ? "40%" : "35%"}
                    bg="whiteAlpha.900"
                    boxShadow="false"
                    position="absolute"
                    top={`${onsenData.disTop[isPC ? 0 : isTablet ? 1 : 2]}%`}
                    left={`${onsenData.disLeft[isPC ? 0 : isTablet ? 1 : 2]}%`}
                    className={`pos_image ${!isPC ? "sp" : "pc"} ${
                      actives[idx] ? "clicked" : ""
                    }`}
                  >
                    <Card
                      shadow="none"
                      overflow="hidden"
                      borderBottomRadius="0"
                    >
                      <Image
                        loading="lazy"
                        w="full"
                        mx="auto"
                        src={`images/${isPC ? "PC" : "Tablet"}/top/${
                          onsenData.src
                        }.webp`}
                        alt={onsenData.place}
                      />
                    </Card>
                    <CustomText
                      font="serif"
                      isLink={true}
                      fontSize={isPC ? "1.5vw" : isTablet ? "2.5vw" : "3vw"}
                      whiteSpace="pre"
                      textAlign="center"
                      py="1"
                    >
                      <a href={onsenData.url} target="_blank" rel="noreferrer">
                        {onsenData.place}
                      </a>
                    </CustomText>
                  </Card>
                )}
              </>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

const chayaData = {
  top: 10 + 10,
  left: 67,
  disTop: [25 + 10, 25 + 10, 25 + 10],
  disLeft: [70, 70, 68],
  place: "滝の茶屋\nそうめん流し",
  src: "chaya",
  url: "https://www.google.co.jp/maps/place/%E6%BB%9D%E3%81%AE%E8%8C%B6%E5%B1%8B/@32.7079946,130.2883314,13.98z/data=!4m14!1m7!3m6!1s0x35400cdabdfd7c29:0x8dbb4a6fda2a7cb1!2z5rud44Gu6Iy25bGL!8m2!3d32.7062008!4d130.2976837!16s%2Fg%2F1tdbtj95!3m5!1s0x35400cdabdfd7c29:0x8dbb4a6fda2a7cb1!8m2!3d32.7062008!4d130.2976837!16s%2Fg%2F1tdbtj95?hl=ja&entry=ttu",
};

const onsenData = {
  top: 40 + 10,
  left: 58,
  disTop: [17 + 10, 10 + 10, 13 + 10],
  disLeft: [17, 5, 10],
  place: "原城温泉",
  src: "masago",
  url: "https://goo.gl/maps/R8jEimhLkYKmGxRU9",
};
