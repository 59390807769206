import { LiteYoutubePlayer } from "@/components/elements/LiteYoutubePlayer";
import { mediaQuery, useMediaQuery } from "@/utils/UseMediaQuery";
import { Box, Flex, Image, Spacer } from "@chakra-ui/react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";

gsap.registerPlugin(ScrollTrigger);

export const Youtube = () => {
  const isPC = useMediaQuery(mediaQuery.pc);
  const isTablet = useMediaQuery(mediaQuery.tablet);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    setAnimation();
  }, []);

  const movieImageRef = useRef<HTMLDivElement>(null);

  const setAnimation = () => {
    gsap.to("#movieImage", {
      scrollTrigger: {
        trigger: "#movieImage",
        start: "top 70%",
        end: "bottom bottom",
        onEnter: () => {
          movieImageRef?.current?.classList.add("active");
        },
      },
    });
  };

  return (
    <Box
      position="relative"
      zIndex="1000"
      w="full"
      pt={isPC ? "3%" : "3%"}
      pb="5%"
    >
      <Box
        id="movieImage"
        className="fade_in"
        ref={movieImageRef}
        mx="auto"
        w={`${isPC ? 55 : isTablet ? 60 : 80}%`}
      >
        <Image
          loading="lazy"
          alt="美川さん　南島原スペシャルムービー"
          src={`images/${
            isPC ? "PC" : isTablet ? "Tablet" : "SP"
          }/titile_movie.webp`}
        />
      </Box>
      {isPC ? (
        <Box>
          {Array(youtubeDatas.length / 2)
            .fill("")
            .map((_, idx) => {
              const data = youtubeDatas.slice(idx * 2, idx * 2 + 2);
              return (
                <Flex m="3% 12%" key={idx}>
                  <LiteYoutubePlayer
                    id={data[0].id}
                    w="50%"
                    title={data[0].title}
                    isOutText={true}
                    thumbnail={`images/${
                      isPC ? "PC" : isTablet ? "Tablet" : "SP"
                    }/youtube/0${idx * 2 + 2}.webp`}
                    isMute={false}
                  />
                  <Spacer mx="2%" />
                  <LiteYoutubePlayer
                    id={data[1].id}
                    w="50%"
                    title={data[1].title}
                    isOutText={true}
                    thumbnail={`images/${
                      isPC ? "PC" : isTablet ? "Tablet" : "SP"
                    }/youtube/0${idx * 2 + 3}.webp`}
                    isMute={false}
                  />
                </Flex>
              );
            })}
        </Box>
      ) : (
        <Box my="3%" mx="auto">
          {youtubeDatas.slice(0, 3).map((data, idx) => {
            return (
              <>
                {idx === 2 ? (
                  <div className="cp_box" key={idx} style={{ width: "100%" }}>
                    <input id="cp01" type="checkbox" />
                    <label htmlFor="cp01"></label>
                    <div className="cp_container">
                      {youtubeDatas.slice(2, 7).map((data, key) => {
                        return (
                          <Box key={key} mb={`${isTablet ? "5%" : "8%"}`}>
                            <LiteYoutubePlayer
                              id={data.id}
                              w={`${isTablet ? "70%" : "95%"}`}
                              title={data.title}
                              isOutText={true}
                              isMute={true}
                              thumbnail={`images/${
                                isPC ? "PC" : isTablet ? "Tablet" : "SP"
                              }/youtube/0${key + 4}.webp`}
                            />
                          </Box>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <Box key={idx} mb={`${isTablet ? "5%" : "8%"}`}>
                    <LiteYoutubePlayer
                      id={data.id}
                      w={`${isTablet ? "70%" : "95%"}`}
                      title={data.title}
                      isOutText={true}
                      isMute={true}
                      thumbnail={`images/${
                        isPC ? "PC" : isTablet ? "Tablet" : "SP"
                      }/youtube/0${idx + 2}.webp`}
                    />
                  </Box>
                )}
              </>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

const youtubeDatas = [
  {
    id: "DZTB9h5i65A",
    title: "水に流せるまち「家族」篇",
  },
  {
    id: "0NrmtxROU0Q",
    title: "水に流せるまち「自撮り」篇",
  },
  {
    id: "h_SxrSrl-T0",
    title: "水に流せるまち「野生動物」篇",
  },
  {
    id: "3OuRRhj366g",
    title: "水に流せるまち「スポーツ」篇",
  },
  {
    id: "SSHRevvHdCg",
    title: "水に流せるまち「渋谷駅」篇",
  },
  {
    id: "4K80aO7sBUk",
    title: "水に流せるまち「小柳津林太郎」篇",
  },
];
