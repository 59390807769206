import CustomText from "@/components/elements/CustomText";
import { Message } from "@/components/message/Message";
import { mediaQuery, useMediaQuery } from "@/utils/UseMediaQuery";
import { AddIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Card,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import { Controller, Scene } from "react-scrollmagic";

import "@css/news.css";
import { useEffect, useState } from "react";

type NewsType = {
  date: string;
  title: string;
  url?: string;
  body?: string;
};

export const News = () => {
  const isPC = useMediaQuery(mediaQuery.pc);
  const isTablet = useMediaQuery(mediaQuery.tablet);
  const isSP = useMediaQuery(mediaQuery.sp);

  const [newsData, setNewsData] = useState(Array<NewsType>);

  const [modalData, setModalData] = useState<NewsType>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    async function fetchJsonData() {
      try {
        const response = await fetch("/newsData.json"); // publicフォルダのルートからの相対パス
        const data = await response.json();
        setNewsData(
          data.reverse().sort((a: NewsType, b: NewsType) => {
            const dateA = new Date(a.date.replace(".", "-"));
            const dateB = new Date(b.date.replace(".", "-"));
            return dateB.getTime() - dateA.getTime();
          }),
        );
      } catch (error) {
        console.error("Error fetching JSON data:", error);
      }
    }

    fetchJsonData();
  }, []);

  return (
    <Box position="relative">
      <Controller>
        <Scene
          duration="80%"
          offset={`${isPC ? "-100%" : isTablet ? "-150%" : "-200%"}`}
          triggerHook="onLeave"
          triggerElement="#newsBg"
        >
          {(progress: number) => {
            const translateY = progress * (isPC ? 300 : isTablet ? 250 : 150);
            return (
              <Box
                backgroundImage={`images/${
                  isPC ? "PC" : isTablet ? "Tablet" : "SP"
                }/taki_bg.webp`}
                backgroundPosition="top"
                position="absolute"
                top="-5%"
                left="0"
                w="100%"
                h={`${
                  isPC
                    ? `${700 + newsData.length * 50}px`
                    : isTablet
                    ? `${550 + newsData.length * 30}px`
                    : `${300 + newsData.length * 20}px`
                }`}
                zIndex="1"
                overflow="hidden"
                transform={`translateY(${translateY}px)`}
              />
            );
          }}
        </Scene>
      </Controller>
      <Box id="newsBg" position="relative" zIndex="10" pt="0%" pb="3%">
        <Spacer py={isPC ? "8%" : isTablet ? "8%" : "4%"} />
        <Box backgroundColor="transparent" m="2% auto">
          <Card
            borderRadius="0px"
            shadow="false"
            mx="auto"
            minW={`${isPC ? "750px" : isTablet ? "426px" : "320px"}`}
            maxW={`${isPC ? "750px" : isTablet ? "650px" : "350px"}`}
            bg="blackAlpha.400"
            p="1% 3%"
          >
            <CustomText
              font="serif"
              pb="4%"
              color="text.onfill"
              fontWeight="400"
              size="xl"
            >
              NEWS
            </CustomText>
            {newsData.map((data, idx) => {
              return (
                <Flex
                  key={idx}
                  className={`newsElement ${
                    data.url || data.body ? "hasLink" : ""
                  }`}
                  p="3% 0%"
                  position="relative"
                  overflow="hidden"
                  onClick={() => {
                    if (data.url) {
                      window.open(data.url, "_blank");
                    } else if (data.body) {
                      setModalData(data);
                      onOpen();
                    }
                  }}
                >
                  <CustomText
                    font="serif"
                    color="text.onfill"
                    w={`${isPC ? "20%" : isTablet ? "25%" : "30%"}`}
                    fontWeight="400"
                    size="md"
                  >
                    {data.date}
                  </CustomText>
                  <CustomText
                    font="serif"
                    color="text.onfill"
                    w={`${isPC ? "80%" : isTablet ? "73%" : "68%"}`}
                    pe="2%"
                    textAlign="justify"
                    fontWeight="400"
                    wordBreak="break-word"
                    size="md"
                    textDecoration={
                      !isPC && (data.url || data.body) ? "underline" : undefined
                    }
                  >
                    {data.title}
                  </CustomText>
                  {data.url && (
                    <ExternalLinkIcon
                      color="text.onfill"
                      my="auto"
                      fontSize={`${isSP ? "10px" : null}`}
                      textAlign="center"
                      verticalAlign="center"
                    />
                  )}
                  {data.body && (
                    <AddIcon
                      ms="1"
                      color="text.onfill"
                      my="auto"
                      fontSize={`${isSP ? "10px" : null}`}
                      textAlign="center"
                      verticalAlign="center"
                    />
                  )}
                </Flex>
              );
            })}
          </Card>
        </Box>
      </Box>
      <Spacer py="2%" />
      <Box position="relative" zIndex="10">
        <Box
          zIndex="1"
          w="100%"
          height="100%"
          position="absolute"
          top={`${isPC ? "15%" : isTablet ? "30%" : "20%"}`}
          bgImage="images/bg1.webp"
          bgPosition="right 50% bottom 30%;"
          // _before={{
          //   content: `""`,
          //   width: "100%",
          //   height: "2%",
          //   position: "absolute",
          //   top: "-1%",
          //   left: 0,
          //   background:
          //   "linear-gradient(0, rgba(255,255,255,1) 0%,rgba(255,255,255,0.9) 20%, rgba(255,255,255,0) 100%)",
          //   "z-index": "-1",
          // }}
        />
        <Box
          py="3%"
          zIndex="10"
          position="relative"
          mx="auto"
          minW={`${isPC ? "700px" : isTablet ? "426px" : "310px"}`}
          maxW={`${isPC ? "700px" : isTablet ? "500px" : "310px"}`}
          bg="white"
          boxShadow="2xl"
        >
          <Message />
        </Box>
      </Box>
      <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader mt="4%" px="5%">
            <CustomText
              size="lg"
              font="serif"
              whiteSpace="pre-line"
              textAlign="justify"
              wordBreak="break-word"
            >
              {modalData?.title}
            </CustomText>
            <Divider mt="2%" height="2px" bg="#143282" />
          </ModalHeader>
          <ModalBody overflow="hidden">
            <Box w="full" px="2%" pt="0%" pb="5%" position="relative">
              <CustomText
                size="md"
                font="serif"
                textAlign="justify"
                overflowY="scroll"
                overflowWrap="break-word"
                wordBreak="break-word"
                whiteSpace="pre-line"
              >
                {modalData?.body && (
                  <ReactMarkdown linkTarget="_blank">
                    {modalData.body}
                  </ReactMarkdown>
                )}
              </CustomText>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
